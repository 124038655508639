import {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon, MapIcon,
  Cog6ToothIcon, UserGroupIcon
} from '@heroicons/react/24/outline'
import {
  FileSpreadsheetIcon,
  Import,
  LogOutIcon,
  StoreIcon
} from "lucide-react";
import {Link, Outlet, useLocation} from "react-router-dom";
import {classNames} from "../helpers";
import {HomeIcon} from "@heroicons/react/20/solid";
import {ReactComponent as Logo} from './route-sched-logo.svg';
import {gql, useQuery} from "@apollo/client";
import _ from "lodash";
import {Button} from "./catalyst/button";
import {useAuth} from "../auth/useAuth";
import {Crisp} from "crisp-sdk-web";
import HelpButton from "./HelpButton";

const QUERY = gql`
  query getUser {
    me {
      name
      email
      pictureUrl
      role
    }
  }
`
const navigation = [
  {name: "Home", href: "/", icon: HomeIcon, regex: /^\/$/},
  {name: 'Plans', href: '/plans', icon: MapIcon, regex: /^\/plans(?:\/[a-zA-Z0-9]+)?$/},
  {name: 'Places', href: '/places', icon: StoreIcon, regex: /^\/places(?:\/[a-zA-Z0-9]+)?$/},
  // {name: "Clients", href: "/clients", icon: UserGroupIcon, regex: /^\/clients(?:\/[a-zA-Z0-9]+)?$/},
  {name: 'Import', href: '/import', icon: Import, regex: /^\/import(?:\/[a-zA-Z0-9]+)?$/},
  {name: "Files", href: "/files", icon: FileSpreadsheetIcon, regex: /^\/files(?:\/[a-zA-Z0-9]+)?$/},
  {name: "Settings", href: "/settings", icon: Cog6ToothIcon, regex: /\/settings\//}
]


const Avatar = ({user}) => {
  return (
    <Link to={"/"}
          className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
    >
      {!_.isNil(user) &&
        <>
          <img
            className="size-8 rounded-full bg-gray-800"
            src={user.pictureUrl}
            alt={user.name}
          />
          <span aria-hidden="true">{user.name}</span>
        </>
      }
    </Link>
  )
}

const LicenseBar = ({isDemo}) => {
  if (!isDemo) return null

  return (
    <>
      <hr
        className={classNames(isDemo ? "bg-orange-500 dark:bg-orange-700" : "bg-blue-500 dark:bg-blue-700", "h-0.5 border-0 z-0")}/>
      <div className="w-full text-center flex align-top">
        <span
          className={classNames(isDemo ? "bg-orange-500" : "bg-blue-500", "mx-auto text-xs font-bold text-white  uppercase px-2 rounded-bl-md rounded-br-md")}>
          {isDemo ? "Demo data" : "Free Version"}
        </span>
      </div>
    </>
  )
}

export default function Layout({children}) {
  const {isDemo} = useAuth()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const {loading, error, data} = useQuery(QUERY, {fetchPolicy: "network-only"})

  const reportFathom = (name, value) => {
    // window.fathom?.trackEvent(name, {_value: value})
  }

  const isCurrentItem = (item) => {
    const location = useLocation()
    const regex = item.regex
    return regex.test(location.pathname)
  }

  if (!isDemo && !_.isUndefined(data)) {
    Crisp.user.setNickname(data.me.name);
    Crisp.user.setEmail(data.me.email);
    // Crisp.session.setData({
    //   user_id : "123456",
    //   plan : "free"
    // });
  }


  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80"/>
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div
                    className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <div className="size-16 shadow-lg shadow-gray-400" title="RouteSched">
                        <Logo className="size-16"/>
                      </div>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-800 text-white'
                                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                  onClick={() => setSidebarOpen(false)}
                                >
                                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true"/>
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                            <li className="">
                              <Link to={"/logout"}
                                    className="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                                <LogOutIcon className="size-6 shrink-0"/>
                                Logout
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-48 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
            <div className="flex h-16 mt-4 shrink-0 items-center">
              <div className="size-16 shadow-lg shadow-gray-400" title="RouteSched">
                <Logo/>
              </div>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link to={item.href}
                              className={classNames(
                                isCurrentItem(item)
                                  ? 'bg-gray-800 text-white'
                                  : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                              )}
                        >
                          <item.icon className="h-6 w-6 shrink-0" aria-hidden="true"/>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                    {isDemo &&
                      <>
                        <li>
                          <a href="https://app.routesched.com" target="_blank" rel="noopener noreferrer">
                            <Button color="green"
                                    className="text-lg font-normal flex gap-x-2 rounded-lg lg:text-sm xl:text-base w-full py-3 capitalize mt-10"
                                    onClick={() => reportFathom("monthly", 19)}
                            >
                              <span>Try The App</span>
                              <span aria-hidden="true">&rarr;</span>
                            </Button>
                          </a>
                        </li>

                        {/*<li>*/}
                        {/*  <a href="https://buy.stripe.com/cN2bKIfwsb7acnKaES" target="_blank" rel="noopener noreferrer">*/}
                        {/*    <Button color="green"*/}
                        {/*            className="text-lg font-normal flex gap-x-2 rounded-lg lg:text-sm xl:text-base w-full py-3"*/}
                        {/*            onClick={() => reportFathom("monthly", 19)}*/}
                        {/*    >*/}
                        {/*      <span>$19/month</span>*/}
                        {/*      <span aria-hidden="true">&rarr;</span>*/}
                        {/*    </Button>*/}
                        {/*  </a>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*  <a href="https://buy.stripe.com/8wM9CA0By5MQ87u28l" target="_blank" rel="noopener noreferrer">*/}
                        {/*    <Button color="green"*/}
                        {/*            className="text-lg font-normal flex gap-x-2 rounded-lg lg:text-sm xl:text-base w-full py-3"*/}
                        {/*            onClick={() => reportFathom("monthly", 19)}*/}
                        {/*    >*/}
                        {/*      <span>Lifetime License $449</span>*/}
                        {/*      <span aria-hidden="true">&rarr;</span>*/}
                        {/*    </Button>*/}
                        {/*  </a>*/}
                        {/*</li>*/}
                      </>
                    }
                  </ul>
                </li>
                <li className="mt-auto">
                </li>
                <li className="">
                  <Link to={"/logout"}
                        className="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                    <LogOutIcon className="size-6 shrink-0"/>
                    Logout
                  </Link>
                </li>
                <li className="-mx-6 ">
                  <Avatar user={data?.me}/>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button type="button" className="-m-2.5 p-2.5 text-gray-400 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
          <Avatar user={data?.me}/>
        </div>

        <LicenseBar isDemo={isDemo}/>

        <main className="py-10 lg:pl-48 min-h-lvh bg-slate-200">
          <div className="px-4 sm:px-6 lg:px-8 h-full min-h-lvh">
            <Outlet/>
          </div>
        </main>

      </div>
    </>
  )
}
