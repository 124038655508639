import {useMemo} from 'react';
import _ from "lodash"

export function useAuth() {
  const DEMO_TOKEN = "demo"

  const jwtToken = useMemo(() => {
    return DEMO_TOKEN
  }, [])

  const isAuthenticated = useMemo(() => {
    return jwtToken !== null
  }, [jwtToken])

  const isDemo = useMemo(() => {
    return jwtToken === DEMO_TOKEN
  }, [jwtToken])

  let signOut = (_callback) => {
    window.location.reload()
  };

  return { jwtToken, signOut, isAuthenticated, isDemo };
}


